import axios from "axios";
import authHeader from './auth-header'
import { decodeToken, isTokenExpired } from "./decode-jwt"
import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";

import { EventStreamContentType, fetchEventSource } from '@microsoft/fetch-event-source';
import { loginRequest } from "authConfig";

import { removeTrailingSlash } from "common/utility"


const API_URL = removeTrailingSlash(process.env.REACT_APP_API_PATH);

const getResponse = async (token: string, style:string, question: string, history: any): Promise<string | null> => {
    try {
        
        let response = await axios.post(
            API_URL + "/api/openai/answer",
            {
                style:style,
                question: question,
                history:history
            },
            {
                headers: { Authorization: 'Bearer ' + token }
            }
        )
        if (response.status === 200) {
            if (response.data.isSuccess) {
                return response.data.content;
            }
        }
    }
    catch (error) {
        console.log(error);
    }

    return null;
};


const getStreamResponse = async (
    token: string,
    style: string,
    mode:string,
    question: string,
    history: any,
    callback: any
): Promise<void> => {
    try {
        let data: string = '';
        let isFirstCall = true;
        let source = fetchEventSource(API_URL + "/api/openai/streamAnswer", {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                Authorization: "Bearer " + token
            },
            body: JSON.stringify({
                style: style,
                question: question,
                history: history,
                mode:mode
            }),
            openWhenHidden:true,
            onmessage(event:any) {
                const delta = JSON.parse(event.data)

                if (isFirstCall == true) {
                    isFirstCall = false;
                    data += delta;
                    callback(question, data, 0);
                }
                else {
                    data += delta;
                    callback(question, data, 1);
                }
            },
            onclose() {
                callback(question,data,2)
            },
            async onopen(event:any): Promise<void> {
                if (event.ok && event.headers.get('content-type') === EventStreamContentType) {
                    return; // everything's good
                } else if (event.status >= 400 && event.status < 500 && event.status !== 429) {
                    console.log("Fetal error on fetchEventSource")
                } else {
                    console.log("Retriable error on fetchEventSource")
                }
            },
            onerror(event:any) {
                console.log('onerror');
                callback(question, data, -1);
                throw new Error("Stopping retries due to an error");
            }

        });
        
        await source;
    } catch (error) {
        console.log(error);
    }

    return;
};


const OpenAIService = {
    getResponse,
    getStreamResponse

}
export default OpenAIService;