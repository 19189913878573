//qnaReducer.ts
export const SET_IS_LOADING_QNA = 'SET_IS_LOADING_QNA';
export const SET_ANSWER_STYLE_QNA = 'SET_ANSWER_STYLE_QNA';
export const SET_CURRENT_PAGE_QNA = 'SET_CURRENT_PAGE_QNA';
export const SET_ROWS_QNA = 'SET_ROWS_QNA';
export const SET_IS_PINNED_QNA = 'SET_IS_PINNED_QNA';
export const SET_QUESTION_QNA = 'SET_QUESTION_QNA';
export const ADD_MESSAGE_QNA = 'ADD_MESSAGE_QNA';
export const UPDATE_MESSAGE_QNA = 'UPDATE_MESSAGE_QNA';
export const DELETE_MESSAGE_QNA = 'DELETE_MESSAGE_QNA';
export const DELETE_MESSAGES_QNA = 'DELETE_MESSAGES_QNA';
export const CLEAR_MESSAGES_QNA = 'CLEAR_MESSAGES_QNA';
export const SET_RESERVE_CONTEXT_QNA = 'SET_RESERVE_CONTEXT_QNA';

export interface MessageItem {
    user: string;
    assistant: string;
}

export interface QnaState {
    isLoading: boolean,
    answerStyle: string,
    currentPage: number,
    reserveContext: boolean,
    rows: number
    isPinned: boolean
    question: string,
    history: MessageItem[],
}

const initialState: QnaState = {
    isLoading: false,
    answerStyle: "Coder",
    currentPage: 1,
    reserveContext: true,
    rows: 4,
    isPinned: false,
    question: "",
    history: []
};


export interface SetIsLoadingAction {
    type: typeof SET_IS_LOADING_QNA;
    payload: {
        isLoading: boolean;
    }
}

export interface SetAnswerStyleAction {
    type: typeof SET_ANSWER_STYLE_QNA;
    payload: {
        style:string;
    }
}

export interface SetCurrentPageAction {
    type: typeof SET_CURRENT_PAGE_QNA;
    payload: {
        index:number
    }
}

export interface SetReserveContextAction {
    type: typeof SET_RESERVE_CONTEXT_QNA;
    payload: {
        reserveContext: boolean;
    }
}

export interface SetRowsAction {
    type: typeof SET_ROWS_QNA;
    payload: {
        rows: number;
    }
}


export interface SetIsPinnedAction {
    type: typeof SET_IS_PINNED_QNA;
    payload: {
        isPinned: boolean;
    }
}

export interface SetQuestionAction {
    type: typeof SET_QUESTION_QNA;
    payload:
    {
        question: string
    }
}



export interface AddMessageAction {
    type: typeof ADD_MESSAGE_QNA;
    payload: MessageItem;
}


export interface DeleteMessageAction {
    type: typeof DELETE_MESSAGE_QNA;
    payload: number;
}

export interface DeleteMessagesAction {
    type: typeof DELETE_MESSAGES_QNA;
    payload: {
        startIndex: number;
        count: number;
    };
}

export interface UpdateMessageAction {
    type: typeof UPDATE_MESSAGE_QNA;
    payload: MessageItem;
}

export interface ClearMessagesAction {
    type: typeof CLEAR_MESSAGES_QNA;
}




export type QnaActionTypes = SetIsLoadingAction | SetAnswerStyleAction | SetCurrentPageAction | SetReserveContextAction
    | SetQuestionAction | SetRowsAction | SetIsPinnedAction
    | AddMessageAction | DeleteMessageAction | DeleteMessagesAction | UpdateMessageAction | ClearMessagesAction;
    

export function setIsLoading(isLoading: boolean): SetIsLoadingAction {
    return {
        type: SET_IS_LOADING_QNA,
        payload: {
            isLoading
        }
    }
}

export function setAnswerStyle(style: string): SetAnswerStyleAction {
    return {
        type: SET_ANSWER_STYLE_QNA,
        payload: {
            style
        }
    };
}

export function setCurrentPage(index: number): SetCurrentPageAction {
    return {
        type: SET_CURRENT_PAGE_QNA,
        payload: {
            index,
        }
    };
}

export function setReserveContext(reserveContext: boolean): SetReserveContextAction {
    return {
        type: SET_RESERVE_CONTEXT_QNA,
        payload:
        {
            reserveContext,
        }
    }
}

export function setIsPinned(isPinned: boolean): SetIsPinnedAction {
    return {
        type: SET_IS_PINNED_QNA,
        payload: {
            isPinned
        }
    }
}

export function setRows(rows: number): SetRowsAction {
    return {
        type: SET_ROWS_QNA,
        payload: {
            rows
        }
    }
}

export function setQuestion(question:string): SetQuestionAction {
    return {
        type: SET_QUESTION_QNA,
        payload: {
            question
        },
    };
}


export function addMessage(message: MessageItem): AddMessageAction {
    return {
        type: ADD_MESSAGE_QNA,
        payload: message,
    };
}

export function deleteMessage(index: number): DeleteMessageAction {
    return {
        type: DELETE_MESSAGE_QNA,
        payload: index,
    };
}

export function deleteMessages(startIndex: number, count: number): DeleteMessagesAction {
    return {
        type: DELETE_MESSAGES_QNA,
        payload: {
            startIndex,
            count,
        },
    };
}
export function updateMessage(message: MessageItem): UpdateMessageAction {
    return {
        type: UPDATE_MESSAGE_QNA,
        payload: message,
    };
}

export function clearMessages(): ClearMessagesAction {
    return {
        type: CLEAR_MESSAGES_QNA,
    };
}

export const qnaReducer = (state= initialState, action: QnaActionTypes): QnaState => {
    switch (action.type) {
        case SET_IS_LOADING_QNA:
            return { ...state, isLoading: action.payload.isLoading };
        case SET_ANSWER_STYLE_QNA:
            return { ...state, answerStyle: action.payload.style };
        case SET_CURRENT_PAGE_QNA:
            return { ...state, currentPage: action.payload.index };
        case SET_QUESTION_QNA:
            return { ...state, question: action.payload.question };
        case SET_RESERVE_CONTEXT_QNA:
            return { ...state, reserveContext: action.payload.reserveContext };
        case SET_ROWS_QNA:
            return { ...state, rows: action.payload.rows };
        case SET_IS_PINNED_QNA:
            return { ...state, isPinned: action.payload.isPinned };
        case ADD_MESSAGE_QNA:
            return { ...state, history: [...state.history, action.payload], currentPage: state.history.length+1 };
        case DELETE_MESSAGE_QNA:
            return {
                ...state, history: state.history.filter((_, index) => index !== action.payload),
            };
        case DELETE_MESSAGES_QNA:
            const { startIndex, count } = action.payload;
            return {
                ...state, history: [
                    ...state.history.slice(0, startIndex),
                    ...state.history.slice(startIndex + count),
                ],
            };
        case UPDATE_MESSAGE_QNA:
            const updatedHistory = state.history.map((message, index) => {
                if (index === state.history.length - 1) {
                    return {
                        user: action.payload.user,
                        assistant: action.payload.assistant
                    };
                }
                return message;
            });
            return {
                ...state,
                history: updatedHistory
            };
        case CLEAR_MESSAGES_QNA:
            return {
                ...state, history:[],
            };

        default:
            return state;
    }
};