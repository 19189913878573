import axios from "axios";
import authHeader from './auth-header'
import { decodeToken, isTokenExpired } from "../services/decode-jwt"
import Cookie from 'universal-cookie';
import {
    PublicClientApplication,
    SilentRequest,
    AuthenticationResult,
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";

import { loginRequest } from "../authConfig";
import { removeTrailingSlash } from "common/utility"


const API_URL = removeTrailingSlash(process.env.REACT_APP_API_PATH);

const getAccessToken = async (instance: any): Promise<string | null> => {
    let accessToken: string | null = null;

    const activeAccount = instance.getActiveAccount();

    if (activeAccount) {
        const accessTokenRequest: SilentRequest = {
            scopes: loginRequest.scopes,
            account: activeAccount,
        };

        try {
            const accessTokenResponse: AuthenticationResult = await instance.acquireTokenSilent(accessTokenRequest);
            accessToken = accessTokenResponse.accessToken;
        }
        catch (error) {
            console.log(error);

            // Check if the error is related to token expiration
            if (error instanceof InteractionRequiredAuthError) {
                try {
                    // Initiate a new sign-in request
                    await instance.acquireTokenRedirect(accessTokenRequest);
                    // Retry acquiring the token silently
                    const accessTokenResponse: AuthenticationResult = await instance.acquireTokenSilent(accessTokenRequest);
                    accessToken = accessTokenResponse.accessToken;
                } catch (signInError) {
                    instance.setActiveAccount(null);
                    console.log(signInError);
                }
            }
        }
    }
    else {
        console.log("no active account");
    }

    return accessToken;
}
const generateDirectlineToken = async (token: string): Promise<string | null> => {

    try {
        let response = await axios.post(
            API_URL + "/api/auth/generateDirectlineToken",
            {},
            {
                headers: { Authorization: 'Bearer ' + token }
            }
        )
        if (response.status === 200) {
            if (response.data.isSuccess) {
                return response.data.content;
            }
        }
    }
    catch (error) {
        console.log(error);
    }

    return null;
};

const generateSpeechToken = async (token: string): Promise<{ token: string, region: string } | null> => {

    const cookie = new Cookie();
    const speechToken = cookie.get('speech-token');

    if (speechToken === undefined) {
        try {
            let response = await axios.post(
                API_URL + "/api/auth/generateSpeechToken",
                {},
                {
                    headers: { Authorization: 'Bearer ' + token }
                }
            )
            if (response.status === 200) {
                if (response.data.isSuccess) {
                    cookie.set('speech-token', response.data.content.region + ':' + response.data.content.token, { maxAge: 540, path: '/' });
                    return response.data.content;
                }
            }

            return null;
        } catch (err: any) {
            console.log(err.response.data);
            return null;
        }
    }
    else {
        // console.log('Token fetched from cookie: ' + speechToken);
        const idx = speechToken.indexOf(':');
        return { token: speechToken.slice(idx + 1), region: speechToken.slice(0, idx) };
    }
}


const register: any = async (userName: string, password: string) => {
    let response = await axios.post("/api/auth/register", {
        userName,
        password,
    });
    return response.data;
};


const login: any = async (userName: string, password: string) => {
    let response = await axios
        .post("/api/auth/signin", {
            userName,
            password,
        })
    if (response.status === 200) {
        if (response.data.isSuccess) {
            let token = {
                jwt: response.data.content.jwtToken,
                refresh: response.data.content.refreshToken
            }
            localStorage.setItem("token", JSON.stringify(token));
        }
    }
    return response.data;

};


const sendResetToken = async (userName: string) => {
    let response = await axios
        .post("/api/auth/sendresettoken", { userName })
    if (response.status === 200) {
        return true;
    }
    return false;
}

const verifyResetToken = async (userName: string, resetToken: string) => {
    let response = await axios
        .post("/api/auth/verifyresettoken", { userName, resetToken })
    if (response.status === 200) {
        if (response.data.isSuccess) {
            let token = {
                jwt: response.data.content.jwtToken,
                refresh: response.data.content.refreshToken
            }
            localStorage.setItem("token", JSON.stringify(token));
        }
        return true;
    }
    return false;
}

const logout = async (userName: string) => {
    let token = localStorage.getItem("token");
    if (token !== null) {
        try {
            let response = await axios.post("/api/auth/signout",
                {
                    userName
                },
                {
                    headers: authHeader(token)
                },
            );

        }
        catch (error: any) {
            let isExpired = isTokenExpired(token);
            if (isExpired) {

            }
        }
    }
    localStorage.removeItem("token");
};
const getCurrentUser = () => {
    let user = localStorage.getItem("token");
    return user == null ? null : JSON.parse(user);
};
const AuthService = {
    register,
    login,
    logout,
    sendResetToken,
    verifyResetToken,
    getCurrentUser,
    getAccessToken,
    generateDirectlineToken,
    generateSpeechToken
}
export default AuthService;