import React from 'react';
import { Button, Box, Typography, IconButton, CircularProgress, Stack, Switch, Select, MenuItem, FormControl, InputLabel, Grid } from '@mui/material';
import { Keyboard, RecordVoiceOver, RestartAlt } from "@mui/icons-material";


interface ChatHeaderProps {
    speechMode: string;
    changeSpeechMode: (event: any) => void;
    handleClearClick: (event: any) => void;
}

const ChatHeader = ({ speechMode, changeSpeechMode, handleClearClick}: ChatHeaderProps) => {
    return (

        <Box sx={{
            display: "flex",
            flexDirection: "row",
        }}>


            <Grid container alignItems="center" justifyContent="center" mb={1} mt={1} ml={2} mr={2} >
                <Grid >
                </Grid>
                <Grid item xs>
                    <Typography variant="h6" align="center">GPT语音聊天助手</Typography>
                </Grid>
                
                <Grid>
                    <FormControl >
                        <InputLabel id="speechModeLabel">语音输入模式</InputLabel>
                        <Select labelId="speechModeLabel"
                            value={speechMode} defaultValue={speechMode} onChange={changeSpeechMode} label="语音输入模式" size="small">
                            <MenuItem value="interactive">
                                <Stack direction="row">
                                    <Keyboard />
                                    <Typography variant="body1" sx={{ ml: 1 }}>交互模式</Typography>
                                </Stack>
                            </MenuItem>
                            <MenuItem value="direct">
                                <Stack direction="row">
                                    <RecordVoiceOver />
                                    <Typography variant="body1" sx={{ ml: 1 }}>直接模式</Typography>
                                </Stack>
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid>
                    <IconButton onClick={handleClearClick} title="清除聊天记录" size="medium">
                        <RestartAlt />
                    </IconButton>
                </Grid>
            </Grid>
        </Box>
    );
};

export default ChatHeader;