import React,{ useState, useEffect } from "react";
import { useMsal } from "@azure/msal-react";
import { AccountCircle } from '@mui/icons-material';
import { Menu, MenuItem, IconButton, Tooltip, Divider, Typography } from '@mui/material';
import { AccountPicker } from "./AccountPicker";

export const SignOutButton = () => {
    const [anchorEl, setAnchorEl] = useState<any>(null);
    const open = Boolean(anchorEl);
    const { instance } = useMsal();
    const [accountSelectorOpen, setOpen] = useState(false);
    const [username, setUsername] = useState<string | null>(null);

    const activeAccount = instance.getActiveAccount();
    useEffect(() => {
        if (activeAccount && activeAccount.name) {
            setUsername(activeAccount.username);
        } else {
            setUsername(null);
        }
    }, [activeAccount]);

    const handleLogout = (logoutType: string) => {
        setAnchorEl(null);

        if (logoutType === "popup") {
            instance.logoutPopup({
                postLogoutRedirectUri: "/",
                mainWindowRedirectUri: "/" // redirects the top level app after logout
            });
        }
        else if (logoutType === "redirect") {
            instance.logoutRedirect({
                postLogoutRedirectUri: "/",
            });
        }
    }

    const handleAccountSelection = () => {
        setAnchorEl(null);
        setOpen(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Tooltip title="Account">
                <IconButton aria-label="账号菜单"
                onClick={(event) => setAnchorEl(event.currentTarget)}
                color="inherit"
            >
                <AccountCircle />
                </IconButton>
            </Tooltip>
            <Menu
                id="menu-appbar"
                anchorEl={anchorEl}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
                open={open}
                onClose={() => setAnchorEl(null)}
            >
                <Typography ml={2} mr={2} variant="subtitle1" color="primary">{username}</Typography>
                <Divider />
                <MenuItem onClick={() => handleAccountSelection()} key="switchAccount">切换账号</MenuItem>
                <MenuItem onClick={() => handleLogout("redirect")} key="logoutRedirect">退出登录</MenuItem>
            </Menu>
            <AccountPicker open={accountSelectorOpen} onClose={handleClose} />
        </div>
    )
};