import React ,{ useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { Backdrop } from '@mui/material'
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import PersonIcon from '@mui/icons-material/Person';
import AddIcon from '@mui/icons-material/Add';
import { loginRequest } from "../authConfig";
import LoginDialog from "./LoginDialog";

export const AccountPicker = (props:any) => {
    const { instance, accounts } = useMsal();
    const { onClose, open } = props;
    const [loginDialogOpen, setLoginDialogOpen] = useState<boolean>(false);
    const showLoginDialog = (loginType: string) => {
        return (
            <LoginDialog
                loginType={loginType}
                dialogOpen={loginDialogOpen}
                setDialogOpen={setLoginDialogOpen}
            />
        );

    }
    const handleListItemClick = (account:any) => {
        if (!account) {
            //instance.loginRedirect({
            //    ...loginRequest,
            //    prompt: "login"
            //})
            onClose();
            instance.setActiveAccount(null);
            setLoginDialogOpen(true)
        } else {
            instance.setActiveAccount(account);
            // To ensure account related page attributes update after the account is changed
            window.location.reload();
            onClose();
        }
    };

    return (
        <>
            {loginDialogOpen && showLoginDialog("redirect")}
            {loginDialogOpen && <Backdrop open={loginDialogOpen} />}
            <Dialog onClose={onClose} aria-labelledby="simple-dialog-title" open={open}>
                <DialogTitle id="simple-dialog-title">设置当前账号</DialogTitle>
                <List>
                    {accounts.map((account) => (
                        <ListItem button onClick={() => handleListItemClick(account)} key={account.homeAccountId}>
                            <ListItemAvatar>
                                <Avatar>
                                    <PersonIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText primary={account.name} secondary={account.username} />
                        </ListItem>
                    ))}

                    <ListItem autoFocus button onClick={() => handleListItemClick(null)}>
                        <ListItemAvatar>
                            <Avatar>
                                <AddIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="添加账号" />
                    </ListItem>
                </List>
            </Dialog>
        </>
        
      );
};