import React, { useState, useContext, useCallback, useEffect } from "react";
import {
    Box,
    Grid,
    Typography,
    Button,
    Select,
    MenuItem,
    FormControlLabel,
    Switch,
    SelectChangeEvent,
    IconButton,
    Tooltip,
    TextField,
} from "@mui/material";
import { Remove, ClearAll, ExpandMore, ExpandLess, Reviews, PushPin } from "@mui/icons-material";
import { AuthenticatedTemplate } from "@azure/msal-react";
import { MultilineInput } from "components/MultilineInput";
import { MarkdownOutput } from "components/MarkdownOutput";
import { useMsal } from "@azure/msal-react";
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'contexts/rootReducer';
import AuthService from "services/auth.service";
import OpenAIService from "services/openai.service";
import {
    addMessage, updateMessage, deleteMessage, clearMessages,
    setIsLoading, setRows, setIsPinned, setAnswerStyle, setCurrentPage, setQuestion, setReserveContext
} from "../contexts/qnaReducer";

const Qna = () => {
    const { instance } = useMsal();
    const dispatch = useDispatch();
    const isLoading = useSelector((state: RootState) => state.Qna.isLoading);
    const answerStyle = useSelector((state: RootState) => state.Qna.answerStyle);
    const currentPage = useSelector((state: RootState) => state.Qna.currentPage);
    const question = useSelector((state: RootState) => state.Qna.question);
    const reserveContext = useSelector((state: RootState) => state.Qna.reserveContext);
    const rows = useSelector((state: RootState) => state.Qna.rows);
    const history = useSelector((state: RootState) => state.Qna.history);
    const isPinned = useSelector((state: RootState) => state.Qna.isPinned);
   
      
    //const [folderPath, setFolderPath] = useState<File[]>([]);
    //const handleFolderSelection = async (e: React.ChangeEvent<HTMLInputElement>) => {
    //    const files = await getFilesRecursively(e.target.files as FileList);
    //    setFolderPath(files);
    //};

    //const getFilesRecursively = async (fileList: FileList): Promise<File[]> => {
    //    let files: File[] = [];
    //    for (const file of Array.from(fileList)) {
    //        if (file.type === "") {
    //            const entries = await file.webkitGetAsEntry().createReader().readEntries();
    //            files = files.concat(await getFilesRecursively(new FileList(entries)));
    //        } else {
    //            files.push(file);
    //        }
    //    }
    //    return files;
    //};

    //const handleSubmit = async () => {
    //    try {
    //        const analyzedFiles: AnalyzedFile[] = [];
    //        for (const file of folderPath) {
    //            const content = await file.text();
    //            const analysis = await analyzeFile(content);
    //            analyzedFiles.push({ file: file.name, analysis });
    //        }
    //        console.log(analyzedFiles);
    //    } catch (error) {
    //        console.error(error);
    //    }
    //};

    // Update the history context based on the status
    const updateCallback = useCallback((question: string, answer: string, status: number) => {
        if (status === 0) {
            dispatch(addMessage({ user: question, assistant: answer }));
        } else if (status === 1) {
            dispatch(updateMessage({ user: question, assistant:answer }));
        } else {
            dispatch(setIsLoading(false));
        }
    }, [dispatch]);

    // Toggle the number of rows in the input field
    const pinRows = () => {
        dispatch(setIsPinned(!isPinned));
    };

    const handleInputBlur = () => {
        if (!isPinned) {
            dispatch(setRows(4));
        }
    };

    // Set rows to 20 when input is focused
    const handleInputFocus = () => {
        if (!isPinned) {
            dispatch(setRows(20));
        }
    };



    // Load the current answer style from localStorage if available
    useEffect(() => {
        let item = localStorage.getItem("currentAnswerStyle");
        if (item != null) {
            dispatch(setAnswerStyle(item));
        }
    }, [dispatch]);

    // Make an API request to get the answer
    const makeApiRequest = useCallback(async (question: string) => {
        dispatch(setIsLoading(true));
        let accessToken = await AuthService.getAccessToken(instance);
        if (accessToken != null) {
            await OpenAIService.getStreamResponse(
                accessToken,
                answerStyle,
                "",
                question,
                reserveContext ? history : [],
                updateCallback
            );
        }
        dispatch(setIsLoading(false));
    }, [answerStyle, currentPage, history, reserveContext]);

    // Handle navigation between pages
    const handlePreviousPage = () => {
        if (currentPage > 1 && !isLoading) {
            dispatch(setCurrentPage(currentPage - 1));
            dispatch(setQuestion(history[currentPage - 2].user));
        }
    };

    const handleNextPage = () => {
        if (currentPage < history.length && !isLoading) {
            dispatch(setCurrentPage(currentPage + 1));
            dispatch(setQuestion(history[currentPage].user));
        }
    };

    const handlePageClick = (index: number) => {
        if (!isLoading) {
            dispatch(setCurrentPage(index));
            dispatch(setQuestion(history[index - 1].user));
        }
    };

    // Handle the change of answer style
    const handleStyleChange = (event: SelectChangeEvent<string>) => {
        let style = event.target.value as string;
        dispatch(setAnswerStyle(style));
        localStorage.setItem("currentAnswerStyle", style);
        dispatch(setReserveContext(false));
    };

    // Remove the current page
    const remove = useCallback(() => {
        let currentPageCopy = currentPage;
        let historyCopy = history;
        if (historyCopy.length > 1) {
            dispatch(deleteMessage(currentPageCopy - 1));
            if (currentPageCopy > historyCopy.length - 1) {
                currentPageCopy--
                dispatch(setCurrentPage(currentPageCopy));
                dispatch(setQuestion(historyCopy[currentPageCopy - 1].user));
            }
            else {
                dispatch(setCurrentPage(currentPageCopy));
                dispatch(setQuestion(historyCopy[currentPageCopy].user));
            }
            
        } else {
            clear();
        }
    }, [history, currentPage]);

    // Clear all history
    const clear = useCallback(() => {
        dispatch(clearMessages());
        dispatch(setCurrentPage(1));
        dispatch(setQuestion(""));
    }, []);

    // Create pagination buttons
    const createPaginationButtons = () => {
        const totalPages = history.length;
        let startPage = Math.max(1, currentPage - 4);
        let endPage = Math.min(totalPages, currentPage + 4);

        if (currentPage <= 5) {
            endPage = Math.min(9, totalPages);
        } else if (totalPages - currentPage < 5) {
            startPage = Math.max(1, totalPages - 8);
        }

        return Array.from({ length: endPage - startPage + 1 }, (_, i) => startPage + i);
    };

    return (
        <AuthenticatedTemplate>
            <Box sx={{ flexGrow: 1, display: "flex", flexDirection: "column", width: "100%", maxWidth: "100%", overflowX: "hidden" ,ml: 2,mr:2 }}>

                {/* Input section */}
                <Grid container alignItems="center">
                    <Grid item mt={2} >
                        <Typography variant="h6" color="inherit" >输入</Typography>
                    </Grid>
                    <Grid item xs mt={2}>
                        <IconButton onMouseDown={pinRows} >
                            <PushPin color={isPinned ? "primary" : "disabled"} />
                        </IconButton>
                    </Grid>
                    {/*<Tooltip title="Code Review">*/}
                    {/*    <IconButton color="inherit" size="small" aria-label="CodeReview" >*/}
                    {/*        <Reviews />*/}
                    {/*    </IconButton>*/}
                    {/*</Tooltip>*/}
                    {/* Remove current page and clear all buttons */}
                    <Grid item mr={1} mt={2} >
                        <Tooltip title="删除当前页">
                            <IconButton color="inherit" size="small" aria-label="删除当前页" onClick={remove}>
                                <Remove />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    <Grid item mr={1} mt={2} >
                        <Tooltip title="清除所有">
                            <IconButton color="inherit" size="small" aria-label="清除所有" onClick={clear}>
                                <ClearAll />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                    {/* Keep context switch */}
                    <Grid item mt={2}>
                        <FormControlLabel
                            control={
                                <Switch color="secondary"
                                    checked={reserveContext}
                                    onChange={() => {
                                        dispatch(setReserveContext(!reserveContext));
                                    }}
                                />
                            }
                            label="保持上下文"
                        />
                    </Grid>
                </Grid>
                <MultilineInput
                    value={question}
                    onChange={(q: string) => dispatch(setQuestion(q))}
                    disabled={isLoading}
                    onSend={makeApiRequest}
                    rows={rows}
                    onFocus={handleInputFocus}
                    onBlur={handleInputBlur}
                />

                {/* Output section */}
                <Grid container alignItems="center" mb={1} mt={1} >
                    <Grid item xs>
                        <Typography variant="h6" color="inherit" >输出</Typography>
                    </Grid>
                    <Grid item>
                        <Select size={"small"}
                            value={answerStyle}
                            onChange={handleStyleChange}
                        >
                            <MenuItem value="Coder">开发</MenuItem>
                            <MenuItem value="Sales">销售</MenuItem>
                            <MenuItem value="Student">学生</MenuItem>
                            <MenuItem value="Translator">翻译</MenuItem>
                            <MenuItem value="Paper">论文润色</MenuItem>
                        </Select>
                    </Grid>
                </Grid>

                <MarkdownOutput answer={history && history.length > 0 && currentPage > 0
                    ? history[currentPage - 1].assistant 
                    : undefined} />
                {/* Pagination */}
                <Box mt={2}>
                    <Button onClick={handlePreviousPage} disabled={currentPage === 1}>
                        &lt;
                    </Button>
                    {createPaginationButtons().map((item, index) => (
                        <Button
                            key={index}
                            onClick={() => handlePageClick(item)}
                            variant={currentPage === item ? "contained" : "text"}
                            color={currentPage === item ? "primary" : undefined}
                        >
                            {item}
                        </Button>
                    ))}
                    <Button onClick={handleNextPage} disabled={currentPage === history.length || !history || history.length === 0}>
                        &gt;
                    </Button>
                </Box>
            </Box>
        </AuthenticatedTemplate>
    );
};

export default Qna;