//settingsReducer.ts
export const SET_SETTINGS_APP = 'SET_SETTINGS_APP';

export interface SettingsState {
    modelType: string | "";
    openAIEndpoint: string | "";
    openAIKey: string | "";
    gpt3_5DeploymentName: string | "";
    gpt4DeploymentName: string | "";
    gpt4_32kDeploymentName: string | "";
    textDavinci003: string | "";
    blobConnectionString: string | "";
    textEmbeddingAda002: string | "";
    azureSearchEndpoint: string | "";
    azureSearchKey: string | "";
    vectorDBEndpoint: string | "";
    speechKey: string | "";
    speechRegion: string | "";
    avatarKey: string | "";
    avatarRegion: string | "";
    trialNumber: string | "";
}

export const initialState: SettingsState = {
    modelType: "GPT3.5",
    openAIEndpoint: "",
    openAIKey: "",
    gpt3_5DeploymentName: "",
    gpt4DeploymentName: "",
    gpt4_32kDeploymentName: "",
    textDavinci003: "",
    blobConnectionString: "",
    textEmbeddingAda002: "",
    azureSearchEndpoint: "",
    azureSearchKey: "",
    vectorDBEndpoint: "",
    speechKey: "",
    speechRegion: "",
    avatarKey: "",
    avatarRegion: "",
    trialNumber: "",
};


export interface SetSettingsAction {
    type: typeof SET_SETTINGS_APP;
    payload: SettingsState
};

export type SettingsActionTypes = SetSettingsAction;


export function setSettings(settings: SettingsState): SetSettingsAction {
    return {
        type: SET_SETTINGS_APP,
        payload: settings
    }
}


export const settingsReducer = (state = initialState, action: SettingsActionTypes): SettingsState => {
    switch (action.type) {
        case SET_SETTINGS_APP:
            return action.payload; 

        default:
            return state;
    }
};