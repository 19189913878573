import { useState } from "react";
import React from 'react';
import { IconButton, Box, Button, Typography } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism'

// CodeBlock component props
interface CodeBlockProps {
    inline?: boolean;
    className?: string;
    children: React.ReactNode[];
}

// CodeBlock component for displaying code snippets
export const CodeBlock: React.FC<CodeBlockProps> = ({ inline, className, children }) => {
    // Extract the language from the className
    const languageMatch = /language-(\w+)/.exec(className || '');
    const language = languageMatch?.[1] || '';

    const codeText = String(children).replace(/\n$/, '');

    const handleCopyClick = async () => {
        try {
            await navigator.clipboard.writeText(codeText);
        } catch (err) {
            console.error("can't copy text': ", err);
        }
    };

    if (inline) {
        return <code className={className} style={{ whiteSpace: 'pre-wrap', wordBreak: "break-word", overflowX: "auto" }}>{children}</code>;
    }

    return (
        <div>
            <IconButton onClick={handleCopyClick} sx={{ float: 'right' }}>
                <ContentCopy />
            </IconButton>
            <SyntaxHighlighter
                style={vscDarkPlus}
                language={language}
                showLineNumbers={true}
                PreTag='div'
                children={codeText}
            />
        </div>
    );
};

export const SpanBlock = (props: any) => {
    if (props.className === 'plain-text') {
        return <span className="plain-text" style={{ whiteSpace: 'pre-wrap', wordBreak: "break-word", overflowX: "auto" }} {...props} />;
    }

    return <span {...props} />;
};

export const ParagraphBlock = (props: any) => {
    return <p style={{ whiteSpace: 'pre-wrap', wordBreak: "break-word", overflowX: "auto" }} {...props} />;
};

export const TextBlock = ({ node, ...props }: any) => {
    if (node.type === 'text' && node.parent.type === 'plainText') {
        return <span className="plain-text" style={{ wordBreak: 'break-word' }} {...props} />;
    }
    return <>{props.children}</>;
};