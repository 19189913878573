import React, { useContext, useState, useEffect } from "react";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Box, Stack, Typography } from "@mui/material";
import { Chat, DriveFileMove, Assistant, Telegram } from "@mui/icons-material";
import Sidebar from "components/Sidebar";
import { useMsal } from "@azure/msal-react";
import Qna from "components/Qna";
import { WebChat } from "components/WebChat"

interface MainProps {
    currentItem: string;
}

// Main component to render different components based on the selected item
const Main = (props: MainProps) => {
    switch (props.currentItem) {
        case "QnA":
            return (
                < Qna />
            );
        case "Chat":
            return (
                <Box sx={{ flexGrow: 1, display: "block", flexDirection: "column" }} >
                    {<WebChat />}
                </Box >
            );
        default:
            return null;
    }
};

// Home component to display the main layout with sidebar and main content
const Home = () => {
    const [currentItem, setCurrentItem] = useState<string>("QnA");

    // Load the current item from sessionStorage if available
    useEffect(() => {
        let item = sessionStorage.getItem("currentHomeItem");
        if (item != null) {
            setCurrentItem(item);
        }
    }, [setCurrentItem]);

    const items = [
        {
            id: "QnA",
            title: "问答助手",
            icon: <Assistant />,
        },
        {
            id: "Chat",
            title: "GPT聊天",
            icon: <Telegram />,
        }
    ];

    // Handle item click and update the current item in state and sessionStorage
    const handleItemClick = (itemId: string) => {
        setCurrentItem(itemId);
        sessionStorage.setItem("currentHomeItem", itemId);
    };

    return (
        <Stack sx={{ flexGrow: 1, display: "flex" }} direction="row">
            <AuthenticatedTemplate>
                <Sidebar
                    items={items}
                    currentItem={currentItem}
                    handleItemClick={handleItemClick}
                />
                <Main currentItem={currentItem} />
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Box>
                </Box>
            </UnauthenticatedTemplate>
        </Stack>
    );
};

export default Home;