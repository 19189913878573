import axios from "axios";
import authHeader from './auth-header'
import { decodeToken, isTokenExpired } from "./decode-jwt"
import {
    InteractionRequiredAuthError,
    InteractionStatus,
} from "@azure/msal-browser";
import { loginRequest } from "authConfig";
import { removeTrailingSlash } from "common/utility"


const API_URL = removeTrailingSlash(process.env.REACT_APP_API_PATH);


const saveSettings = async (token: string, data:any ): Promise<boolean> => {
    try {
        const response = await axios.post(API_URL + "/api/settings", data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.status === 200) {
            return true;
        }
        console.error('Error saving settings:', response.statusText);
    }
    catch (error) {
        console.log(error);
    }

    return false;
};

const getSettings = async (token: string): Promise<any | null> => {
    try {
        const response = await axios.get(API_URL + "/api/settings", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
            },
        });

        if (response.status === 200) {
            const data = response.data;
            return data;
        }
        console.error('Error getting settings:', response.statusText);
    }
    catch (error) {
        console.log(error);
    }

    return null;
};

const checkAndCreateSettings = async (token: string): Promise<void> => {
    const settings = await getSettings(token);
    if (!settings) {
        const emptySettings = {
            openAIEndpoint: '',
            openAIKey: '',
            gpt3_5DeploymentName: '',
            gpt4DeploymentName: '',
            gpt4_32kDeploymentName: '',
            textDavinci003: '',
            blobConnectionString: '',
            textEmbeddingAda002: '',
            azureSearchEndpoint: '',
            azureSearchKey: '',
            vectorDBEndpoint: '',
            speechKey: '',
            speechRegion: '',
            avatarKey: '',
            avatarRegion:''
        };
        await saveSettings(token, emptySettings);
    }
};

const SettingsService = {
    saveSettings,
    getSettings,
    checkAndCreateSettings,

}
export default SettingsService;