import React, { useRef, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { useLocation } from 'react-router-dom';

const About = () => {
    const videoFile = '/assets/intro.webm';
    const videoRef = useRef<HTMLVideoElement | null>(null);
    const handleVideoClick = (event: React.MouseEvent<HTMLVideoElement>) => {
        event.preventDefault();
        const videoElement = videoRef.current;
        if (videoElement) {
            if (videoElement.paused) {
                videoElement.play();
            } else {
                videoElement.pause();
            }
        }
    };

    const handleVisibilityChange = () => {
        if (document.visibilityState === 'visible') {
            console.log(`document.visibilityState:${document.visibilityState},`)
            const videoElement = videoRef.current;
            if (videoElement && videoElement === document.pictureInPictureElement) {
                document.exitPictureInPicture();
            }
        }
    };


    useEffect(() => {
        window.addEventListener('visibilitychange', handleVisibilityChange);
        
        return () => {
            window.removeEventListener('visibilitychange', handleVisibilityChange);
            
            const videoElement = videoRef.current;
            if (videoElement) {
                videoElement.pause();
            }
        };
    }, []);

    return (
        <React.Fragment>
            <div>
                <Typography variant="body1" ml={2}>如果有任何问题和建议，请联系:</Typography>
                <Link href="mailto:hanxia@microsoft.com" ml={2}>hanxia@outlook.com</Link>
            </div>
        </React.Fragment>
    );
};

export default About;