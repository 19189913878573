import { useState } from "react";
import React from 'react';
import { IconButton, Box, Button, Typography } from "@mui/material";
import { ContentCopy } from "@mui/icons-material";
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { vscDarkPlus } from 'react-syntax-highlighter/dist/esm/styles/prism'
import ReactMarkdown from 'react-markdown';
import { CodeBlock, SpanBlock, ParagraphBlock, TextBlock } from './MarkdownBlock'
import remarkGfm from 'remark-gfm'
// MarkdownOutput component props
interface Props {
    answer?: string;
    isSelected?: boolean;
}


/**
 * MarkdownOutput component for displaying answers
 */
export const MarkdownOutput = ({ answer, isSelected }: Props) => {
    const [showMarkdown, setShowMarkdown] = useState(true);

    const handleClick = (event: React.MouseEvent) => {
        event.stopPropagation();
        setShowMarkdown(!showMarkdown);
    };

    //const CustomSpanComponent = (props:any) => {
    //    if (props.className === 'plain-text') {
    //        return <span className="plain-text" style={{ whiteSpace: 'pre-wrap', wordBreak: "break-word", overflowX:"auto" }} {...props} />;
    //    }
        
    //    return <span {...props} />;
    //};

    //const CustomParagraphComponent = (props:any) => {
    //    return <p style={{ whiteSpace: 'pre-wrap', wordBreak: "break-word", overflowX: "auto" }} {...props} />;
    //};

    //const CustomTextComponent = ({ node, ...props }:any) => {
    //    if (node.type === 'text' && node.parent.type === 'plainText') {
    //        return <span className="plain-text" style={{ wordBreak: 'break-word' }} {...props} />;
    //    }
    //    return <>{props.children}</>;
    //};

    return (
        <Box
            display="flex"
            flexDirection="column"
            flexGrow={1}
            mt={1}
            sx={{
                border: '1px solid',
                borderColor: 'divider',
                borderRadius: '4px',
                p: 2,
                position: 'relative',
                
            }}
        >
            <Typography
                variant="caption"
                component="span"
                sx={{
                    position: 'absolute',
                    top: -12,
                    right: 8,
                    backgroundColor: 'background.paper',
                    padding: '0 4px',
                    cursor: 'pointer',
                }}
                onClick={handleClick}
            >
                {showMarkdown ? '纯文本' : 'Markdown'}
            </Typography>
            {showMarkdown ? (
                <ReactMarkdown components={{ code: CodeBlock, text: TextBlock, span: SpanBlock, p: ParagraphBlock }} remarkPlugins={[remarkGfm]}>
                    {answer || ''}
                </ReactMarkdown>
            ) : (
                    <div style={{ whiteSpace: 'pre-wrap' }}>{answer || ''}</div>
            )}
        </Box>
    );
};
