import React, { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { ThemeProvider } from '@mui/material/styles';
import { BrowserRouter as Router } from "react-router-dom";
import { theme } from "./styles/theme";
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig } from './authConfig';
import App from './App';
import SettingsService from './services/settings.service';
import AuthService from "services/auth.service"

export const msalInstance = new PublicClientApplication(msalConfig);

// Default to using the first account if no account is active on page load
if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
    // Account selection logic is app dependent. Adjust as needed for different use cases.
    for (let account of msalInstance.getAllAccounts() ){
            msalInstance.setActiveAccount(account);
            break;
    }
}

//This will update account state if a user signs in from another tab or window
msalInstance.enableAccountStorageEvents();

msalInstance.addEventCallback((event: any) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        //if (account.tenantId === msalConfig.auth.authority.split("/").pop()) {
            msalInstance.setActiveAccount(account);
        //}
        
    }
});

msalInstance.addEventCallback(async (event: any) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
        const account = event.payload.account;
        msalInstance.setActiveAccount(account);

        // Get access token and check user settings
        const accessToken = await AuthService.getAccessToken(msalInstance);
        if (accessToken) {
            await SettingsService.checkAndCreateSettings(accessToken);
        }
    }
});

const root = createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <StrictMode>
        <Router>
            <ThemeProvider theme={theme}>
                <App pca={msalInstance} />
            </ThemeProvider>
        </Router>
    </StrictMode>
);
