
import React, { useState, useEffect, Fragment } from 'react';
import { AuthenticatedTemplate } from "@azure/msal-react"
import { Box, Typography, Stack, Button, List, ListItem, TextField} from "@mui/material";
import KBImport from "components/KBImport";
import { School, Face3} from "@mui/icons-material";
import Sidebar from "components/Sidebar";
import AvatarCreator from '../../components/AvatarCreator';
const Main = (props: any) => {
    const [content, setContent] = useState<string>("");

    const handleChange = (content: string) => {
        setContent(content);
    };  
    const handleSubmit = () => {
        console.log("Submitted content:", content);
        setContent("test");
        // Add your submit logic here  
    };
    switch (props.currentItem) {
        case 'KB':
            return (
                <Fragment>
                    <AuthenticatedTemplate>
                        <Box sx={{ flexGrow: 1, ml: 2, mr: 2 }} >
                            <Box display="flex" justifyContent="center">
                                <Typography variant="h6">知识库</Typography>
                            </Box>
                            <KBImport/>
                        </Box >
                    </AuthenticatedTemplate>
                </Fragment>
            );
        default: return null;
    }
}

const Admin = () => {
    const [currentItem, setCurrentItem] = useState<string>("KB");

    useEffect(() => {
        let item = sessionStorage.getItem("currentAdminItem")
        if (item != null) {
            setCurrentItem(item);
        }
    }, [setCurrentItem]);

    const items = [
        {
            id: 'KB',
            title: '知识库',
            icon: <School />,
        },
        //{
        //    id: 'Avatar',
        //    title: 'Video library',
        //    icon: <Face3 />,
        //},
    ];
    const handleItemClick = (itemId: string) => {
        setCurrentItem(itemId);
        sessionStorage.setItem('currentAdminItem', itemId);
    };

    return (
        <Fragment>
            <AuthenticatedTemplate>
                <Stack sx={{ flexGrow: 1, display: 'flex' }} direction="row"  >
                    <Sidebar
                        items={items}
                        currentItem={currentItem}
                        handleItemClick={handleItemClick}
                    />
                    <Main currentItem={currentItem} />
                </Stack>
            </AuthenticatedTemplate>
        </Fragment>
    );
};

export default Admin;