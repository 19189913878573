import React, { useState, useCallback } from "react";
import { useMsal } from "@azure/msal-react";
import { Button, Backdrop, Tooltip } from "@mui/material";
import { msalConfig} from "../authConfig";
import { loginRequest } from '../authConfig';
import LoginDialog  from "./LoginDialog";

/**
 * Renders a button which, when selected, will open a popup for login
 */
export const SignInButton = () => {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);
    const showLoginDialog = (loginType: string) => {
        return (
            <LoginDialog
                loginType={loginType}
                dialogOpen={dialogOpen}
                setDialogOpen={setDialogOpen}
            />
        );
        
    }

    const handleLogin = useCallback(() => {
        setDialogOpen(true)
    }, [setDialogOpen])

    return (
        <>
            {dialogOpen && showLoginDialog("redirect")}
            {dialogOpen && <Backdrop open={dialogOpen} />}
                <Button variant="contained" aria-label="登录你的账号"
                    onClick={() => handleLogin()}>
                    Sign in
                </Button>          
        </>
        
    );
}