import React, { useState, useRef, useCallback, useEffect } from 'react';
import Chat, {
    Bubble,
    MessageProps,
    useMessages,
    QuickReplyItemProps,
    useQuickReplies,
    Card,
    CardTitle,
    CardText,
    List,
    ListItem,
    Flex,
    FlexItem,
    ScrollView,
    Icon,
    IconButton,
    ToolbarItemProps,
} from '@chatui/core';
import { ComposerHandle } from '@chatui/core/lib/components/Composer';
import "@chatui/core/dist/index.css";
import thunk from 'redux-thunk';
import "./chatui-theme.css";
import {
    SpeechConfig,
    SpeechSynthesizer,
    SpeechRecognizer,
    AudioConfig,
    SpeakerAudioDestination,
    AutoDetectSourceLanguageConfig,
    RecognitionEventArgs,
    SpeechSynthesisResult,
    ResultReason,
    CancellationReason
} from 'microsoft-cognitiveservices-speech-sdk';
import { useMsal } from "@azure/msal-react";
import remarkGfm from 'remark-gfm'
import AuthService from "services/auth.service";
import OpenAIService from "services/openai.service";
import { Box } from '@mui/material';

import ReactMarkdown from 'react-markdown';
import { CodeBlock, SpanBlock, ParagraphBlock, TextBlock } from './MarkdownBlock'
import { useSelector, useDispatch } from 'react-redux';
import { RootState } from 'contexts/rootReducer';
import {
    MessageItem, setEnableSpeech, setMessages, setSpeechMode, setQueryMode} from 'contexts/webChatReducer';
import ChatHeader from "./ChatHeader"
import { Height } from '@mui/icons-material';

const SVGIcons = () => (
    <svg xmlns="http://www.w3.org/2000/svg" style={{ display: 'none' }}>
        <symbol id="icon-stop" viewBox="0 0 32 32">
            <circle cx="16" cy="16" r="13" stroke="currentColor" strokeWidth="2" fill="none" />
            <path d="M10 10h12v12H10z" />
        </symbol>
        <symbol id="icon-trash" viewBox="0 0 512 512">
            <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32px" d="M112,112l20,320c.95,18.49,14.4,32,32,32H348c17.67,0,30.87-13.51,32-32l20-320" />
            <line stroke="#000" strokeLinecap="round" strokeWidth="32px" x1stroke-miterlimit="10" x1="80" x2="432" y1="112" y2="112" />
            <path fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32px" d="M192,112V72h0a23.93,23.93,0,0,1,24-24h80a23.93,23.93,0,0,1,24,24h0v40" />
            <line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32px" x1="256" x2="256" y1="176" y2="400" />
            <line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32px" x1="184" x2="192" y1="176" y2="400" />
            <line fill="none" stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32px" x1="328" x2="320" y1="176" y2="400" />
        </symbol>
        {/*<symbol id="icon-mic" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round">*/}
        {/*    <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>*/}
        {/*    <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>*/}
        {/*    <line x1="12" y1="19" x2="12" y2="23"></line>*/}
        {/*    <line x1="8" y1="23" x2="16" y2="23"></line>*/}
        {/*</symbol>*/}
        <symbol id="icon-mic" viewBox="0 0 32 32" fill="currentColor" stroke="#000" strokeWidth="1" strokeLinecap="round" strokeLinejoin="round">
            <path d="M16,27A10,10,0,0,1,6,17a1,1,0,0,1,2,0,8,8,0,0,0,16,0,1,1,0,0,1,2,0A10,10,0,0,1,16,27Z" />
            <path d="M16,31a1,1,0,0,1-1-1V26a1,1,0,0,1,2,0v4A1,1,0,0,1,16,31Z" />
            <path d="M16,23a6,6,0,0,1-6-6V7A6,6,0,0,1,22,7V17A6,6,0,0,1,16,23ZM16,3a4,4,0,0,0-4,4V17a4,4,0,0,0,8,0V7A4,4,0,0,0,16,3Z" />
        </symbol>

        <symbol id="icon-volume" fill="none" viewBox="0 0 24 24">
            <path d="M14.7541 15C15.9961 15 17.0029 16.0069 17.0029 17.2489V17.8243C17.0029 18.7186 16.6833 19.5834 16.1018 20.2628C14.5324 22.0963 12.1453 23.0011 8.99988 23.0011C5.85401 23.0011 3.468 22.096 1.9017 20.2617C1.32194 19.5828 1.00342 18.7193 1.00342 17.8266V17.2489C1.00342 16.0069 2.01027 15 3.25229 15H14.7541ZM14.7541 16.5H3.25229C2.8387 16.5 2.50342 16.8353 2.50342 17.2489V17.8266C2.50342 18.3622 2.69453 18.8803 3.04239 19.2877C4.29569 20.7554 6.26157 21.5011 8.99988 21.5011C11.7382 21.5011 13.7058 20.7553 14.9623 19.2874C15.3112 18.8798 15.5029 18.3609 15.5029 17.8243V17.2489C15.5029 16.8353 15.1676 16.5 14.7541 16.5ZM19.0538 1.40364C19.4135 1.19813 19.8716 1.32306 20.0771 1.6827C21.1678 3.59117 21.7499 5.75412 21.7499 8.00008C21.7499 10.2536 21.1638 12.4235 20.0662 14.3365C19.8601 14.6958 19.4017 14.82 19.0424 14.6138C18.6832 14.4077 18.559 13.9493 18.7652 13.5901C19.7332 11.9027 20.2499 9.98993 20.2499 8.00008C20.2499 6.01691 19.7367 4.11023 18.7748 2.42693C18.5693 2.0673 18.6942 1.60916 19.0538 1.40364ZM8.99988 3.0047C11.7613 3.0047 13.9999 5.24328 13.9999 8.0047C13.9999 10.7661 11.7613 13.0047 8.99988 13.0047C6.23845 13.0047 3.99988 10.7661 3.99988 8.0047C3.99988 5.24328 6.23845 3.0047 8.99988 3.0047ZM15.5884 3.39951C15.9485 3.19476 16.4063 3.32068 16.6111 3.68076C17.3537 4.98671 17.7499 6.46544 17.7499 8.00008C17.7499 9.53822 17.3518 11.0202 16.606 12.3282C16.4009 12.6881 15.9428 12.8135 15.583 12.6083C15.2232 12.4031 15.0978 11.9451 15.303 11.5853C15.9205 10.5021 16.2499 9.27594 16.2499 8.00008C16.2499 6.72712 15.922 5.50362 15.3071 4.4222C15.1024 4.06212 15.2283 3.60425 15.5884 3.39951ZM8.99988 4.5047C7.06688 4.5047 5.49988 6.0717 5.49988 8.0047C5.49988 9.9377 7.06688 11.5047 8.99988 11.5047C10.9329 11.5047 12.4999 9.9377 12.4999 8.0047C12.4999 6.0717 10.9329 4.5047 8.99988 4.5047Z" fill="#212121" />
        </symbol>

        <symbol id="icon-keyboard" stroke="currentColor" viewBox="0 0 16 16">
            <path d="M14 5a1 1 0 0 1 1 1v5a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1V6a1 1 0 0 1 1-1h12zM2 4a2 2 0 0 0-2 2v5a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V6a2 2 0 0 0-2-2H2z" /><path d="M13 10.25a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm0-2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-5 0A.25.25 0 0 1 8.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 8 8.75v-.5zm2 0a.25.25 0 0 1 .25-.25h1.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-1.5a.25.25 0 0 1-.25-.25v-.5zm1 2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-5-2A.25.25 0 0 1 6.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 6 8.75v-.5zm-2 0A.25.25 0 0 1 4.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 4 8.75v-.5zm-2 0A.25.25 0 0 1 2.25 8h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 2 8.75v-.5zm11-2a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-2 0a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm-2 0A.25.25 0 0 1 9.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 9 6.75v-.5zm-2 0A.25.25 0 0 1 7.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 7 6.75v-.5zm-2 0A.25.25 0 0 1 5.25 6h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5A.25.25 0 0 1 5 6.75v-.5zm-3 0A.25.25 0 0 1 2.25 6h1.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-1.5A.25.25 0 0 1 2 6.75v-.5zm0 4a.25.25 0 0 1 .25-.25h.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-.5a.25.25 0 0 1-.25-.25v-.5zm2 0a.25.25 0 0 1 .25-.25h5.5a.25.25 0 0 1 .25.25v.5a.25.25 0 0 1-.25.25h-5.5a.25.25 0 0 1-.25-.25v-.5z" />
        </symbol>
    </svg>
);


type MessageWithoutId = Omit<MessageProps, '_id'>;

let messageId: number = 0;
let ttsConfig: SpeechConfig;
let sttConfig: SpeechConfig;
let recognizer: SpeechRecognizer;
let synthesizer: SpeechSynthesizer | null;
let player: SpeakerAudioDestination;

export const WebChat = () => {

    const autoDetectSourceLanguageConfig = AutoDetectSourceLanguageConfig.fromOpenRange();
    const { instance } = useMsal();
    const dispatch = useDispatch();
    const chatMsgs = useSelector((state: RootState) => state.WebChat.chatMsgs);
    const chatMsgsRef = useRef(chatMsgs);

    const initialMessages: MessageWithoutId[] = [
        {
            type: 'text',
            content: { text: '你好，我是紫龙聊天机器人，有什么能够帮你的吗？' },
            position: "left",
            user: {
                avatar:
                    './openai.png',
            },
        }
    ];
    function convertMessageItemToMessageWithoutId(message: MessageItem) {
        const userMessage: MessageWithoutId = {
            type: 'text',
            content: { text: message.user },
            position: 'right',
            user: { avatar: './user.png' },
        };

        const assistantMessage: MessageWithoutId = {
            type: 'text',
            content: { text: message.assistant },
            position: 'left',
            user: { avatar: './openai.png' },
        };

        return [userMessage, assistantMessage];
    }

    const combinedMessages = [
        ...initialMessages,
        ...chatMsgsRef.current.flatMap(convertMessageItemToMessageWithoutId),
    ];
    const { messages, appendMsg, setTyping, prependMsgs, updateMsg, resetList } = useMessages(combinedMessages);
    let isTyping = false;
    const enableSpeech = useSelector((state: RootState) => state.WebChat.enableSpeech);
    const enableSpeechRef = useRef<boolean>(enableSpeech);
    const enableSpeechInitialized = useRef(false);
    const [isUnmounting, setIsUnmounting] = useState(false);
    const [speechIcon, setSpeechIcon] = useState('mic');
    const queryMode = useSelector((state: RootState) => state.WebChat.queryMode);
    const isReplyingRef = useRef(false);

    const isRecognizingRef = useRef(false);
    const settings = useSelector((state: RootState) => state.Settings);
    const speechMode = useSelector((state: RootState) => state.WebChat.speechMode);
    const insteractiveModeRef = useRef<boolean>(speechMode === "interactive" ? true : false);
    const composerRef = useRef<ComposerHandle>(null);
    const [speechToken, setSpeechToken] = useState("");
    const [speechRegion, setSpeechRegion] = useState("");
    // 发送回调
    const handleSend = (type: string, val: string) => {
        if (type === 'text' && val.trim()) {
            appendMsg({
                type: 'text',
                content: { text: val.trim() },
                position: 'right',
                user: { avatar: './user.png' },
            })
            setTyping(true)
            isTyping = true;
            makeApiRequest(val.trim());
        }
    }



    const updateCallback = useCallback(async (question: string, answer: string, status: number) => {
        if (status === 0) {

            messageId++;
            appendMsg({
                type: 'text',
                content: { text: answer.trim() },
                position: 'left',
                _id: messageId,
                user: {
                    avatar: './openai.png'
                }

            });
        } else if (status === 1) {
            updateMsg(messageId, {
                type: 'text',
                content: { text: answer.trim() },
                position: 'left',
                _id: messageId,
                user: {
                    avatar: './openai.png'
                }
            });
        } else {
            setTyping(false);
            isTyping = false;
            if (enableSpeechRef.current) {
                speakText(answer);
            }
            else {
                isReplyingRef.current = false;
            }

            let msg: MessageItem = {
                user: question,
                assistant: answer
            }
            chatMsgsRef.current = [...chatMsgsRef.current, msg];;
            dispatch(setMessages(chatMsgsRef.current));

            if (chatMsgs.length > 50) {
                var currentChatMsgs = chatMsgsRef.current;
                const reducedChatMsgs = currentChatMsgs.slice(currentChatMsgs.length / 2);
                chatMsgsRef.current = reducedChatMsgs;
                dispatch(setMessages(chatMsgsRef.current));
            }
        }
    }, [chatMsgs]);

    const makeApiRequest = useCallback(async (question: string) => {
        let accessToken = await AuthService.getAccessToken(instance);
        let type = "";
        if (accessToken != null) {
            if (enableSpeechRef.current) {
                type = "Common"
            }
            await OpenAIService.getStreamResponse(
                accessToken,
                type,
                queryMode,
                question,
                chatMsgsRef.current,
                updateCallback
            );
        }
    }, [chatMsgs,updateCallback,queryMode]);


    const toolbar: ToolbarItemProps[] = [
        {
            type: 'mic',
            icon: speechIcon,
            title: 'Speech'
        }
    ];

    function startSpeech(): boolean {
        if (recognizer) {
            isReplyingRef.current = false;
            setSpeechIcon('stop');
            if (isRecognizingRef.current) {
                sttStopRecognition(() => {
                    sttStartRecognition();
                });
            }
            else {
                sttStartRecognition();
            }
            return true;
        }
        else {
            return false
        }
    }

    function stopSpeech(): boolean {
        
        sttStopRecognition();
        setSpeechIcon('mic');
        player?.pause();
        player?.close();
        return true;
    }

    const handleToolbarClick = (item: ToolbarItemProps) => {
        if (item.type === 'mic') {
            if (!enableSpeechRef.current) {
                if (startSpeech()) {
                    enableSpeechRef.current = true;
                    dispatch(setEnableSpeech(enableSpeechRef.current));
                }
            }
            else {
                if (stopSpeech()) {
                    enableSpeechRef.current = false;
                    dispatch(setEnableSpeech(enableSpeechRef.current));
                }
            }
        }


    }


    const sttStartRecognition = () => {

        if (recognizer) {
            recognizer.recognized = (s: any, e: any) => {
                if (e.result.reason === ResultReason.RecognizedSpeech) {
                    console.log(`RECOGNIZED: Text=${e.result.text}`)
                    let recognizedText = e.result.text
                    if (recognizedText.trim() !== "") {
                        if (!isReplyingRef.current) {
                            isReplyingRef.current = true;
                            if (!insteractiveModeRef.current) {
                                handleSend('text', recognizedText)
                            }
                            else {
                                composerRef.current?.setText(recognizedText);
                            }
                        }
                    }
                } else if (e.result.reason === ResultReason.NoMatch) {
                    console.log('NOMATCH: Speech could not be recognized.');
                }
            }

            recognizer.canceled = (s: any, e: any) => {
                console.log(`CANCELED: Reason=${e.reason}`)

                if (e.reason === CancellationReason.Error) {
                    console.log(`"CANCELED: ErrorCode=${e.errorCode}`)
                    console.log(`"CANCELED: ErrorDetails=${e.errorDetails}`)
                }
                stopSpeech();
            }

            recognizer.sessionStopped = (s: any, e: any) => {
                console.log('\n    Session stopped event.')
            }

            console.log('\n    start recognition.')

            recognizer.startContinuousRecognitionAsync(() => {
                isRecognizingRef.current = true;
                console.log("startContinuousRecognitionAsync");
            });

        }
        else {
            console.error("Recognizer is not initialized.");
        }
    }

    const sttStopRecognition = (callback?: () => void) => {
        if (recognizer) {
            recognizer.stopContinuousRecognitionAsync(() => {
                isRecognizingRef.current = false;
                if (callback) {
                    callback();
                }
                console.log("stopContinuousRecognitionAsync");
            });
        }
        else {
            console.error("Recognizer is not initialized.");
        }
    }

    const speakText = (text: string) => {
        if (enableSpeechRef.current) {
            player = new SpeakerAudioDestination();

            player.onAudioStart = function (_) {
                // 在开始语音合成之前设置为 true
                isReplyingRef.current = true;
                console.log("Player has started.");
            };
            player.onAudioEnd = function (_) {
                console.log("Player has ended.");
                isReplyingRef.current = false;
            };
            const speakerAudioConfig = AudioConfig.fromSpeakerOutput(player);
            synthesizer = SpeechSynthesizer.FromConfig(
                ttsConfig,
                autoDetectSourceLanguageConfig,
                speakerAudioConfig,
            )
            if (synthesizer) {


                synthesizer.speakTextAsync(
                    text,
                    function (result: SpeechSynthesisResult) {
                        if (
                            result.reason === ResultReason.SynthesizingAudioCompleted
                        ) {
                            console.log('synthesising audio completed.');
                            synthesizer?.close();

                            return result.audioData
                        } else {
                            console.error(
                                'Speech synthesis canceled, ' +
                                result.errorDetails +
                                '\nDid you set the speech resource key and region values correctly?',
                            );
                            isReplyingRef.current = false;
                        }
                    },
                )
            }
        }
    }

    function handleQuickReplyClick(item: QuickReplyItemProps) {
        //if (item.code === 'ClearChat') {
        //    dispatch(setMessages([]));
        //    resetList()
        //    appendMsg(initialMessages[0])
        //}
    }

    function handleClearClick(event: any) {
        chatMsgsRef.current = [];
        dispatch(setMessages(chatMsgsRef.current));
        resetList()
        appendMsg(initialMessages[0])
    }

    const changSpeechMode = (event: any) => {

        insteractiveModeRef.current = !insteractiveModeRef.current;
        dispatch(setSpeechMode(insteractiveModeRef.current ? "interactive" : "direct"));
        if (isTyping == false && isReplyingRef.current) {
            isReplyingRef.current = false;
        }
    }

    const changeQueryMode = (event: any) => {
        const { name, value } = event.target;
        dispatch(setQueryMode( value ));
    }


    function renderMessageContent(msg: MessageProps) {
        const { type, content } = msg;

        // 根据消息类型来渲染
        switch (type) {
            case 'text':
                return (
                    <Bubble>
                        <ReactMarkdown components={{ code: CodeBlock, text: TextBlock, span: SpanBlock, p: ParagraphBlock }} remarkPlugins={[remarkGfm]}>
                            {content.text || ''}
                        </ReactMarkdown>
                    </Bubble>);

            // return <Bubble content={content.text} />;
            case 'image':
                return (
                    <Bubble type="image">
                        <img src={content.picUrl} alt="" />
                    </Bubble>
                );
            default:
                return null;
        }
    }

    useEffect(() => {
        const cleanup = () => {
            if (isUnmounting) {
                if (enableSpeechRef.current) {
                    stopSpeech();
                }
                console.log("stop speech when swtich router");
            }
        };

        return cleanup;
    }, [isUnmounting]);

    useEffect(() => {
        return () => {
            setIsUnmounting(true);
        };
    }, []);

    useEffect(() => {
        let timerId: NodeJS.Timeout;
        async function refreshSpeechToken() {
            let accessToken = await AuthService.getAccessToken(instance);
            if (accessToken != null) {
                let result = await AuthService.generateSpeechToken(accessToken);
                if (result != null) {
                    sttConfig.authorizationToken = result.token;
                    ttsConfig.authorizationToken = result.token;
                }
                timerId = setTimeout(refreshSpeechToken, 3 * 60 * 1000);

            }
        };
        async function getSpeechToken() {
            let accessToken = await AuthService.getAccessToken(instance);
            if (accessToken != null) {
                let result = await AuthService.generateSpeechToken(accessToken);
                if (result != null) {
                    setSpeechToken(result.token);
                    setSpeechRegion(result.region);
                    sttConfig = SpeechConfig.fromAuthorizationToken(result.token, result.region);
                    ttsConfig = SpeechConfig.fromAuthorizationToken(result.token, result.region);

                    const audioConfig = AudioConfig.fromDefaultMicrophoneInput();
                    const languages: string[] = ["zh-CN", "en-US"];
                    const autoLanguageConfig = AutoDetectSourceLanguageConfig.fromLanguages(languages);
                    recognizer = SpeechRecognizer.FromConfig(
                        sttConfig,
                        autoLanguageConfig,
                        audioConfig);
                    timerId = setTimeout(refreshSpeechToken, 3* 60 * 1000);


                }
            }
        }

        getSpeechToken();

        // Clean up the timer when the component is unmounted
        return () => {
            clearTimeout(timerId);
        };
    }, [instance, settings.speechRegion, settings.speechKey, settings.trialNumber]);

    return (
        <>
            <SVGIcons />
            <Box height='calc(100vh - 70px)'>
            
                <Chat 
                    renderNavbar={() => {
                        return (<ChatHeader speechMode={speechMode} handleClearClick={handleClearClick} changeSpeechMode={changSpeechMode}/>);
                    }}
                    locale="zh-CN"

                    placeholder="在这里输入你的消息..."
                    wideBreakpoint={'0'}
                    toolbar={toolbar}
                    //rightAction={{ icon: 'compass' }}
                    //messagesRef={msgRef}
                    onToolbarClick={handleToolbarClick}
                    recorder={{ canRecord: false }}
                    messages={messages}
                    composerRef={composerRef}
                    renderMessageContent={renderMessageContent}
                    //onInputChange={inputChangeCallback}
                    //quickReplies={quickReplies}
                    onQuickReplyClick={handleQuickReplyClick}
                    onSend={handleSend}
                />
            </Box>

        </>

    );
};